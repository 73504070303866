import { Block, filterBlocks } from '@lib/features/blocks/blocks';
import { useViewBlocks } from '@lib/store';
import { assertUnreachable } from '@liquorice/allsorts-craftcms-nextjs';
import dynamic from 'next/dynamic';
import { BlocksAnchorMenu } from './BlocksAnchorMenu';
import { BlockProvider, BlocksContext, BlocksContextProvider } from './useBlock';

type BlockSkeletonProps = {
  // block: Block;
  height?: number;
};

const BlockSkeleton = ({ height }: BlockSkeletonProps) => {
  return (
    <div
      style={{
        height: height || 100,
      }}
    />
  );
};

const blockLoader = (block: Block, props?: BlockSkeletonProps) =>
  function Skeleton() {
    return <BlockSkeleton {...props} />;
  };

const BlockComponent = ({ block }: { block: Block }) => {
  switch (block.__typename) {
    case 'blocks_accordion_BlockType':
      const Accordion = dynamic(() => import('@component/Accordion/AccordionBlock'), {
        ssr: true,
        loading: blockLoader(block, { height: 200 }),
      });
      return <Accordion {...block} />;

    case 'blocks_cta1_BlockType':
      const Cta1Block = dynamic(() => import('@component/CtaBlock/Cta1Block'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <Cta1Block {...block} />;

    case 'blocks_cta2_BlockType':
      const Cta2Block = dynamic(() => import('@component/CtaBlock/Cta2Block'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <Cta2Block {...block} />;

    case 'blocks_dualCards_BlockType':
      const DualCards = dynamic(() => import('@component/DualCards'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <DualCards {...block} />;

    case 'blocks_entryCard_BlockType':
      const EntryCardBlock = dynamic(() => import('@components/EntryCard/EntryCardBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <EntryCardBlock variant="feature" {...block} />;

    case 'blocks_eventCards_BlockType':
      const EventCardsBlock = dynamic(() => import('@components/EventCards/EventCardsBlock'), {
        ssr: true,
        loading: blockLoader(block, { height: 360 }),
      });
      return <EventCardsBlock {...block} />;

    case 'blocks_everydayEvents_BlockType':
      const EverydayEventsBlock = dynamic(
        () => import('@components/EventCards/EverydayEventsBlock'),
        { ssr: false, loading: blockLoader(block) }
      );
      return <EverydayEventsBlock {...block} />;

    case 'blocks_featuredEvents_BlockType':
      const FeaturedEvents = dynamic(() => import('@component/FeaturedEvents'), {
        ssr: true,
        loading: blockLoader(block, { height: 1200 }),
      });
      return <FeaturedEvents {...block} />;

    case 'blocks_form_BlockType':
      const FormBlock = dynamic(() => import('@component/FormBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <FormBlock {...block} />;

    case 'blocks_image_BlockType':
      const ImageBlock = dynamic(() => import('@component/ImageBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <ImageBlock {...block} />;

    case 'blocks_imageAndText_BlockType':
      const ImageAndText = dynamic(() => import('@component/ImageAndText'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <ImageAndText {...block} />;

    case 'blocks_imageCarousel_BlockType':
      const ImageCarouselBlock = dynamic(() => import('@component/ImageCarouselBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <ImageCarouselBlock {...block} />;

    case 'blocks_imageGallery_BlockType':
      const ImageGalleryBlock = dynamic(() => import('@components/ImageGalleryBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });

      return <ImageGalleryBlock {...block} />;

    case 'blocks_latestArticles_BlockType':
      const LatestArticles = dynamic(() => import('@component/LatestArticles'), {
        ssr: true,
        loading: blockLoader(block),
      });

      return <LatestArticles {...block} />;

    case 'blocks_profileCard_BlockType':
      const ProfileCardBlock = dynamic(() => import('@components/ProfileCardBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });

      return <ProfileCardBlock {...block} />;

    case 'blocks_resourceItem_BlockType':
      const DownloadItemBlock = dynamic(() => import('@component/Downloads/DownloadItemBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });

      return <DownloadItemBlock {...block} />;

    case 'blocks_resources_BlockType':
      const DownloadsBlock = dynamic(() => import('@component/Downloads/Downloads'), {
        ssr: true,
        loading: blockLoader(block),
      });

      return <DownloadsBlock {...block} />;

    case 'blocks_richText_BlockType':
      const RichContent = dynamic(() => import('@component/RichContent'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <RichContent {...block} />;

    case 'blocks_singleCardExternal_BlockType':
      const SingleCardBlock = dynamic(() => import('@components/SingleCardBlock'), {
        ssr: true,
        loading: blockLoader(block, { height: 380 }),
      });

      return <SingleCardBlock variant="feature" {...block} />;

    case 'blocks_subscribeForm_BlockType':
      const SubscribeFormBlock = dynamic(() => import('@components/SubscribeFormBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });

      return <SubscribeFormBlock {...block} />;

    case 'blocks_table_BlockType':
      const TableBlock = dynamic(() => import('@component/TableBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <TableBlock {...block} />;

    case 'blocks_video_BlockType':
      const VideoBlock = dynamic(() => import('@component/Video/VideoBlock'), {
        ssr: true,
        loading: blockLoader(block),
      });
      return <VideoBlock {...block} />;

    /**
     * Freak blocks...
     */
    case 'socialLinks_links_BlockType':
      return <></>;

    default:
      return assertUnreachable(block);
  }
};

type BlocksProps = {
  blocks?: Block[];
} & BlocksContext;

const Blocks = ({ blocks: maybeBlocks, ...context }: BlocksProps) => {
  const viewBlocks = useViewBlocks();
  const blocks = maybeBlocks ? filterBlocks(maybeBlocks).blocks : viewBlocks.blocks;
  const hasAnchorMenu = context.showAnchorMenu;

  return blocks.length ? (
    <BlocksContextProvider value={context}>
      {hasAnchorMenu && <BlocksAnchorMenu />}
      {blocks.map((v, i) => (
        <BlockProvider key={i + 1} value={v}>
          <BlockComponent block={v.block} />
        </BlockProvider>
      ))}
    </BlocksContextProvider>
  ) : (
    <></>
  );
};

export default Blocks;
